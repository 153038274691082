//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-272:_896,_4760,_1248,_9424,_6952,_4512,_7536,_1712;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-272')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-272', "_896,_4760,_1248,_9424,_6952,_4512,_7536,_1712");
        }
      }catch (ex) {
        console.error(ex);
      }